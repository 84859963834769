<template>
  <form @submit.prevent="subscribe" class="bg-white rounded-xl py-6 px-4">
    <TsRow append-class="m-0">
      <div>
        <TsTypography
          append-class="mb-0 flex items-center text-base gap-1 font-bold leading-6"
          as="h4"
        >
          {{ useTranslation("subscribeForNotification", "Abonneer u op notificatie") }}
        </TsTypography>
        <TsTypography
          append-class="mb-0 flex items-center text-xs gap-1 "
          as="p"
        >
          {{
            useTranslation(
              "willBeNotified",
              "U wordt per e-mail op de hoogte gesteld wanneer dit product weer beschikbaar is."
            )
          }}
        </TsTypography>
      </div>
      <TsIcon
        name="bitcoin-icons:cross-filled"
        size="26"
        @click="emit('close')"
        class="absolute top-3 right-3 text-idle-black hover:bg-info/10 rounded transition-colors cursor-pointer"
      />
    </TsRow>

    <TsRow append-class="m-0 my-4">
      <TsInput
        type="email"
        placeholder="Enter email"
        size="sm"
        v-model="userEmail"
        aria-disabled="true"
        disabled
        input-class="text-natural-grey cursor-not-allowed select-none"
        append-class="bg-natural-light-grey/25"
        :data-testid="'stock-notify-email-input-' + randomId"
      />
    </TsRow>

    <TsRow append-class="m-0 gap-1">
      <TsColumn append-class="px-0">
        <TsButton
          block
          :label="stockBack"
          size="sm"
          outlined
          @click="emit('close')"
          :data-testid="'stock-notify-back-btn-' + randomId"
        />
      </TsColumn>
      <TsColumn append-class="px-0">
        <TsButton
          block
          icon="mdi:trolley-outline"
          :label="stockSubscribe"
          type="submit"
          :is-loading="productStore.is_stock_subscribe_loading"
          size="sm"
          :data-testid="'stock-notify-subscribe-btn-' + randomId"
        />
      </TsColumn>
    </TsRow>
  </form>
</template>

<script setup lang="ts">
import { toast } from "vue3-toastify";

type Props = {
  product?: any;
  loading?: boolean;
};
const productStore = useProductStore();
const authStore = useAuthStore();
const accountStore = useAccountStore();
const trolleyStore = useTrolleyStore();
const stockSubscribe = useTranslation("stockNotifySubscribe", "Abonneren");
const stockBack = useTranslation("stockNotifyBack", "Rug");
const randomId = ref("");
const userEmail = ref(authStore.user?.email || "");
const props = withDefaults(defineProps<Props>(), {});
const emit = defineEmits<{
  close: any;
  subscribe: [email: string];
}>();
onMounted(() => {
  randomId.value = useRandomUUID();
});
const subscribe = async () => {
  let response = await productStore.subscribeProduct();
  (accountStore.stock_notifications as any).push(response);
  // set latest notification response
  emit("subscribe", userEmail.value);
  productStore.subscription_successful_modal_visible = true;
  productStore.notify_user_email = userEmail.value;
  toast("Successfully subscribed", {
    autoClose: true,
    type: "success",
  });
};
</script>
